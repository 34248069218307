<template>
    <section style="overflow-y:hidden">
        <section class="nav-container m-t-18">
            <a @click="navActiveKey = item.key" :class="item.key == navActiveKey ? 'active' : ''" v-for="item in navMap" :key="item.key">{{item.name}}</a>
        </section>
        <section class="m-t-16 scroll-container">
            <project-info v-if="navActiveKey === 1" />
            <project-company v-if="navActiveKey === 2" />
            <project-team v-if="navActiveKey === 3" />
            <project-accout v-if="navActiveKey === 4" />
            <project-role v-if="navActiveKey === 5" />
            <project-position v-if="navActiveKey === 6" />
            <project-workType v-if="navActiveKey === 7" />
            <company-type v-if="navActiveKey === 8" />
        </section>
    </section>
</template>

<script>
import projectInfo from './components/info'
import projectCompany from './components/company'
import projectTeam from './components/team'
import projectAccout from './components/accout'
import projectRole from './components/role'
import projectPosition from './components/position'
import projectWorkType from './components/workType'
import companyType from './components/companyType'
export default {
    data(){
        return{
            navMap:[
                {name:'项目信息',key:1},
                {name:'参建单位',key:2},
                {name:'项目班组',key:3},
                {name:'账号列表',key:4},
                {name:'角色列表',key:5},
                {name:'职务管理',key:6},
                {name:'工种管理',key:7},
                {name:'单位类型管理',key:8},
            ],
            navActiveKey:1
        }
    },
    components:{
        projectInfo,projectCompany,projectTeam,projectAccout,projectRole,projectPosition,projectWorkType,companyType
    }
}
</script>
<style lang="scss" scoped>
    .scroll-container{
        height: calc(100% - 80px);
    }
</style>