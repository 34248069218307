<template>
    <section>
        <section class="search-container">
            <a-form layout="inline" class="m-t-14">
                <a-form-item label="单位类型">
                    <a-input class="search-input" placeholder="请输入" v-model="searchInfo.companyTypeName" />
                </a-form-item>
                <a-form-item label="状态">
                    <a-select class="search-select" placeholder="全部" v-model="searchInfo.status">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option :value="1">启用</a-select-option>
                        <a-select-option :value="0">禁用</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" @click="queryCompanyTypeList" class="m-r-8"><a-icon type="search"  />查询</a-button>
                    <a-button @click="reset"><a-icon type="reload"  />重置</a-button>
                </a-form-item>
            </a-form>
            <a-row class="right-action-panel m-t-14">
                <a-button type="primary" @click="showCompanyTypeModal(null)" class="item">添加单位类型</a-button>
            </a-row>
        </section>

        <section class="list-table-container m-t-18">
            <a-table size="small" :columns="columns" :data-source="companyTypeLists" :pagination="{total,pageSize,hideOnSinglePage:true,showTotal:(total) => `共${total}条`,size:'default',showQuickJumper:true,showSizeChanger:false}" @change="changePageNum">
                 <!-- 状态 -->
                <span slot="status" :style="{color:text == 1 ? '#52C41A' :'#FF555E'}" slot-scope="text">{{text == 1 ? '启用' : '禁用'}}</span>
                <!-- 操作 -->
                <a slot="action" slot-scope="text,list" v-if="list.type == 1">
                    <a @click="showCompanyTypeModal(list)">编辑</a>
                    <a-divider style="color:#222" type="vertical" />
                    <a-popconfirm :title="'确定'+(list.status == 1 ? '禁用' : '启用')+'该类型?'" @confirm="changeProjectCompanyType(list)">{{list.status == 1 ? '禁用' : '启用'}}</a-popconfirm>
                    <a-divider style="color:#222" type="vertical" />
                    <a-popconfirm title="确定删除该类型?" @confirm="deleteItem(list)">删除</a-popconfirm>
                </a>
                <span v-else slot="action">-</span>
            </a-table>
        </section>

         <!-- modal -->
        <a-modal centered width="660px" class="modal-container" :title="companyTypeModal.title" v-model="companyTypeModal.visible" :footer="false" :destroyOnClose="true">
            
           <a-form :form="form">
                <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="单位类型">
                   <a-input placeholder="请输入" v-decorator="['companyTypeName',{rules:[{required:true,message:'单位类型不能为空'}]}]" class="form-input"></a-input>
               </a-form-item>
               <!-- <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="人员类型">
                   <a-select placeholder="请选择" v-decorator="['personnelType',{rules:[{required:true,message:'请选择人员类型'}]}]">
                       <a-select-option value="1">管理人员</a-select-option>
                       <a-select-option value="2">施工人员</a-select-option>
                   </a-select>
               </a-form-item> -->
              <!-- <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="排序">
                   <a-input-number v-decorator="['sort',{rules:[{required:true,message:'排序不能为空'}]}]"></a-input-number>
               </a-form-item> -->
               
    
               <a-row class="form-btn-group">
                   <a-button class="btn-save"  @click="submit" :loadding="submitLoadding" :disabled="submitLoadding"><a-icon v-if="!submitLoadding" type="save" />保存</a-button>
                   <a-button class="btn-cancel"  @click="companyTypeModal.visible = false">取消</a-button>
               </a-row>
           </a-form>

        </a-modal>

    </section>
</template>


<script>
const columns = [
    {title:'序号',dataIndex:'key',align:'center',key:'key',width:80},
    {title:'单位类型',dataIndex:'companyTypeName',align:'center',key:'companyTypeName'},
    // {title:'人员类型',dataIndex:'personnelType',align:'center',key:'personnelType'},
    {title:'状态',dataIndex:'status',scopedSlots:{customRender: "status"},align:'center',key:'status'},
    {title:'操作',scopedSlots:{customRender: "action"},align:'center'},
]
export default {
    data(){
        return{
            columns,
            total:0,    //总条数
            pageSize:9,
            pageNum:1,  //当前页
            searchInfo:{},  //搜索
            companyTypeLists:[],
            companyTypeModal:{
                visible:false,
                title:'添加类型'
            },
            companyTypeId:''    //单位类型id
        }
    },
     beforeCreate(){
        this.form = this.$form.createForm(this)
    },
    created(){
        this.queryCompanyTypeList()
    },
    computed:{
        submitLoadding(){
            return this.$store.state.submitLoadding
        }
    },
    methods:{
        showCompanyTypeModal(list){
            this.companyTypeId = list ? list.id : ''
            this.companyTypeModal.title = list ? '编辑单位类型' : '新增单位类型'
            this.companyTypeModal.visible = true
            this.companyTypeId && this.backfillForm(list)
        },
        //编辑时，回填数据到表单
        backfillForm(list){
            let formData = {
                companyTypeName:list.companyTypeName
            }
            this.$nextTick(() =>{
                this.form.setFieldsValue(formData)
            })
        },
        //查询单位类型列表
        queryCompanyTypeList(){
            let params = {...this.searchInfo}
            params.current = this.pageNum
            params.size = this.pageSize
            this.$api.queryCompanyTypeList(params).then(res =>{
                if(res.code === 200){
                    this.companyTypeLists = (res.data && res.data.records) || []
                    this.companyTypeLists.forEach((item,ind) =>{
                        item.key = (this.pageSize*(this.pageNum - 1))+ind+1
                    })
                    this.total = (res.data && res.data.total) || 0
                }
            })
        },
        //分页
        changePageNum(p){
            this.pageNum = p.current
            this.queryCompanyTypeList()
        },
        //重置
        reset(){
            this.searchInfo = {}
            this.pageNum = 1
            this.queryCompanyTypeList()
        },
        //提交表单数据（添加、编辑）
        submit(){
            this.form.validateFields((err,formData) =>{
                if(!err){
                    this.$store.commit('SET_SUBMITLOADDING',true)
                    if(this.companyTypeId){
                        formData.id = this.companyTypeId
                    }
                    
                    let apiName = this.companyTypeId ? 'updateProjectCompanyType' : 'insertProjectCompanyType'
                    this.$api[apiName](formData).then(res =>{
                        if(res.code === 200){
                            this.companyTypeModal.visible = false
                            this.queryCompanyTypeList()
                        }
                    })
                }
            })
        },
        //删除
        deleteItem(list){
            this.$api.deleteProjectCompanyType({id:list.id}).then(res =>{
                if(res.code === 200){
                    this.queryCompanyTypeList()
                }
            })
        },
        //启用禁用
        changeProjectCompanyType(list){
            let status = list.status == 1 ? 0 : 1
            this.$api.changeProjectCompanyType({id:list.id,status}).then(res =>{
                if(res.code === 200){
                    this.queryCompanyTypeList()
                }
            })
        }
    }
}
</script>