<template>
    <section>
        <section class="search-container">
            <a-form layout="inline" class="m-t-14">
                <a-form-item label="单位名称">
                    <a-input class="search-input" placeholder="请输入" v-model="searchInfo.companyName" />
                </a-form-item>
                <a-form-item label="单位类型">
                    <a-select showSearch option-filter-prop="children" class="search-select" placeholder="全部" v-model="searchInfo.companyTypeId" >
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option v-for="item in companyTypeMap" :key="item.id" :value="item.id">{{item.companyTypeName}}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="项目负责人">
                    <a-input class="search-input" placeholder="请输入" v-model="searchInfo.manager"  />
                </a-form-item>
                <a-form-item label="状态">
                    <a-select class="search-select" placeholder="全部" v-model="searchInfo.status" >
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option value="1">在场</a-select-option>
                        <a-select-option value="2">离场</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="标记" >
                    <a-select class="search-select" placeholder="全部" v-model="searchInfo.flag">
                        <a-select-option value="">全部</a-select-option>
                         <a-select-option value="1">已标记 </a-select-option>
                        <a-select-option value="0">未标记</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" class="m-r-8" @click="search"><a-icon type="search" />查询</a-button>
                    <a-button @click="reset"><a-icon type="reload" />重置</a-button>
                </a-form-item>
            </a-form>
            <a-row class="right-action-panel m-t-14">
                <a-button @click="showCompanyModal(null)" class="item" type="primary">
                    添加单位
                </a-button>
            </a-row>
        </section>
        <section class="list-single-container" v-if="companyLists.length">
            
            <a-row class="list-border list-item m-t-18" v-for="(item,ind) in companyLists" :key="ind">
                <a-row class="action-btn-group">
                    <a-row>
                        <a-button size="small" class="m-b-8" @click="showCompanyDetailsModal(item)">查看</a-button>
                        <a-button v-if="item.status == 1" size="small" class="m-b-8" @click="showCompanyEditModal(item)">退场</a-button>
                        <a-button v-if="item.status == 1" size="small" class="m-b-8" @click="showCompanyModal(item)">编辑</a-button>
                        <a-popconfirm v-if="item.status == 2" title="确定删除该参建单位?" @confirm="deleteItem(item)"><a-button  size="small">删除</a-button></a-popconfirm>
                    </a-row>
                </a-row>
                <p>{{item.companyName}}</p>
                <a-row class="m-b-10">
                    <a-col :span="6">单位类型：{{item.companyTypeName}}</a-col>
                    <a-col :span="6">项目负责人：{{item.manager}}</a-col>
                    <a-col :span="6">入场时间： {{item.entranceTime}}</a-col>
                    <a-col :span="6">
                        单位状态：
                        <span :style="{color:item.status == 1 ? '#52C41A' :'#FF555E'}">{{item.status == 1? '在场' : item.status == 2 ? '离场' : ''}}</span>
                    </a-col>
                </a-row>
                <a-row class="m-b-10">
                    <a-col :span="6">公司法人：{{item.legalPerson}}</a-col>
                    <a-col :span="6">项目负责人电话：{{item.managerPhone}}</a-col>
                    <a-col :span="6">离场时间： {{item.exitTime}}</a-col>
                </a-row>
                <a-row>
                    <a-col :span="6">公司电话：{{item.companyPhone}}</a-col>
                    <a-col :span="6">班组数量：{{item.teamNumber || 0}}</a-col>
                    <a-col :span="6">是否标记： {{item.flag == 1 ? '是' : '否' }}</a-col>
                </a-row>
            </a-row>
        </section>
        <empty v-else :height="430" />
        <!-- 分页 -->
        <section class="pagination-container">
            <a-pagination 
            size="default" 
            :pageSize="pageSize" 
            :current="pageNum" 
            :total="total" 
            hide-on-single-page 
            :show-total="(total) => `共${total}条`"
            :showQuickJumper="true"
		    :showSizeChanger="false"
            @change="changePageNum" />
        </section>
        <!-- a-modal -->
        <a-modal centered width="860px" class="modal-container" :title="companyDetailsModal.title" v-model="companyDetailsModal.visible" :footer="false" :destroyOnClose="true">
            <section class="info-container">
                <section class="info-item-wrap">
                   <p class="info-title ">单位信息</p>
                   <a-row class="m-t-18">
                       <a-col :span="10">
                           <a-row class="info-item">
                               <a-col class="key">单位名称</a-col>
                               <a-col class="val">{{companyDetails.companyName}}</a-col>
                           </a-row>
                       </a-col>
                       <a-col :span="10">
                           <a-row class="info-item">
                               <a-col class="key">单位类型</a-col>
                               <a-col class="val">{{companyDetails.companyTypeName}}</a-col>
                           </a-row>
                       </a-col>
                       <a-col :span="10">
                           <a-row class="info-item">
                               <a-col class="key">注册资金(元)</a-col>
                               <a-col class="val">{{companyDetails.registeredAmount}}</a-col>
                           </a-row>
                       </a-col>
                       <a-col :span="10">
                           <a-row class="info-item">
                               <a-col class="key">公司法人</a-col>
                               <a-col class="val">{{companyDetails.legalPerson}}</a-col>
                           </a-row>
                       </a-col>
                       <a-col :span="10">
                           <a-row class="info-item">
                               <a-col class="key">公司电话</a-col>
                               <a-col class="val" >{{companyDetails.companyPhone}}</a-col>
                           </a-row>
                       </a-col>
                        <a-col :span="10">
                           <a-row class="info-item">
                               <a-col class="key" style="font-size:12px">统一社会信用代码</a-col>
                               <a-col class="val">{{companyDetails.socialCreditCode}}</a-col>
                           </a-row>
                       </a-col>
                        <a-col :span="10">
                           <a-row class="info-item">
                               <a-col class="key">公司地址</a-col>
                               <a-col class="val">{{companyDetails.companyAddress}}</a-col>
                           </a-row>
                       </a-col>
                      
                        <a-col :span="10">
                           <a-row class="info-item">
                               <a-col class="key">是否标记</a-col>
                               <a-col class="val">{{companyDetails.flag == 1 ? '是' : '否'}}</a-col>
                           </a-row>
                       </a-col>
                        <a-col :span="20">
                           <a-row class="info-item">
                               <a-col class="key">公司描述</a-col>
                               <a-col class="val">{{companyDetails.briefIntroduct}}</a-col>
                           </a-row>
                       </a-col>
                   </a-row>
               </section>
               <section class="info-item-wrap">
                   <p class="info-title">进场离场信息</p>
                   <a-row class="m-t-18">
                       <a-col :span="8">
                           <a-row class="info-item">
                               <a-col class="key">进场时间</a-col>
                               <a-col class="val">{{companyDetails.entranceTime}}</a-col>
                           </a-row>
                       </a-col>
                       <a-col :span="8" v-if="companyDetails.status == 2">
                           <a-row class="info-item">
                               <a-col class="key">离场时间</a-col>
                               <a-col class="val">{{companyDetails.exitTime}}</a-col>
                           </a-row>
                       </a-col>
                       <a-col :span="8">
                           <a-row class="info-item">
                               <a-col class="key">状态</a-col>
                               <a-col class="val" :style="{color:companyDetails.status == 1 ? '#52C41A' :'#FF555E'}">{{companyDetails.status == 1? '在场' : companyDetails.status == 2 ? '离场' : ''}}</a-col>
                           </a-row>
                       </a-col>
                       <a-col :span="20" v-if="companyDetails.files && companyDetails.files.length">
                           <a-row class="info-item">
                               <a-col class="key">离场附件</a-col>
                                <a-col class="val upload-preview-wrap">
                                    <a class="file-item m-r-10" v-for="(item,ind) in companyDetails.files" :key="ind">
                                        <preview-image :data="item.codeUrl" :size="100" />
                                    </a>
                                </a-col>
                           </a-row>
                       </a-col>
                   </a-row>
               </section>
            </section>
        </a-modal>
        <a-modal centered width="960px" class="modal-container" :title="companyModal.title" v-model="companyModal.visible" :footer="false" :destroyOnClose="true">
            <a-form :form="form">
                <a-row>
                    <a-col :span="12">
                        <a-form-item :label-col="{span:companyModal.labelCol}" :wrapper-col="{span:companyModal.wrapperCol}" label="单位名称">
                            <a-input placeholder="请输入" v-decorator="['companyName',{rules:[{required:true,message:'单位名称不能为空'},{max:30,message:'最大为30个字符'}]}]" class="form-input"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item :label-col="{span:companyModal.labelCol}" :wrapper-col="{span:companyModal.wrapperCol}" label="单位类型">
                            <a-select showSearch option-filter-prop="children" placeholder="请选择" v-decorator="['companyTypeId',{rules:[{required:true,message:'请选择单位类型'}]}]">
                                <a-select-option v-for="item in companyTypeMap" :key="item.id" :value="item.id">{{item.companyTypeName}}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item :label-col="{span:companyModal.labelCol}" :wrapper-col="{span:companyModal.wrapperCol}" label="注册资金(万元)">
                            <a-input placeholder="请输入" v-decorator="['registeredAmount',{rules:[{required:true,message:'注册资金不能为空'},{validator:validator.moneyReg}]}]" class="form-input"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item :label-col="{span:companyModal.labelCol}" :wrapper-col="{span:companyModal.wrapperCol}" label="公司法人">
                            <a-input placeholder="请输入" v-decorator="['legalPerson',{rules:[{required:true,message:'公司法人不能为空'},{max:10,message:'最大为10个字符'}]}]" class="form-input"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item :label-col="{span:companyModal.labelCol}" :wrapper-col="{span:companyModal.wrapperCol}" label=" 统一社会信用代码">
                            <a-input placeholder="请输入" v-decorator="['socialCreditCode',{rules:[{required:true,message:'统一社会信用代码不能为空'},{max:50,message:'最大为50个字符'}]}]" class="form-input"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item :label-col="{span:companyModal.labelCol}" :wrapper-col="{span:companyModal.wrapperCol}" label=" 公司电话">
                            <a-input placeholder="请输入" v-decorator="['companyPhone',{rules:[{required:true,message:'公司电话不能为空'},{max:15,message:'最大为15个字符'}]}]" class="form-input"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item :label-col="{span:companyModal.labelCol}" :wrapper-col="{span:companyModal.wrapperCol}" label=" 项目负责人">
                            <a-input placeholder="请输入" v-decorator="['manager',{rules:[{required:true,message:'项目负责人不能为空'},{max:10,message:'最大为10个字符'}]}]" class="form-input"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item :label-col="{span:companyModal.labelCol}" :wrapper-col="{span:companyModal.wrapperCol}" label=" 项目负责人电话">
                            <a-input placeholder="请输入" v-decorator="['managerPhone',{rules:[{required:true,message:'项目负责人电话不能为空'},{max:15,message:'最大为15个字符'}]}]" class="form-input"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item :label-col="{span:companyModal.labelCol}" :wrapper-col="{span:companyModal.wrapperCol}" label=" 公司地址">
                            <a-input placeholder="请输入" v-decorator="['companyAddress',{rules:[{required:true,message:'公司地址不能为空'},{max:100,message:'最大为100个字符'}]}]" class="form-input"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item :label-col="{span:companyModal.labelCol}" :wrapper-col="{span:companyModal.wrapperCol}" label=" 公司描述">
                            <a-input placeholder="请输入" v-decorator="['briefIntroduct',{rules:[{required:true,message:'公司描述不能为空'},{max:100,message:'最大为100个字符'}]}]" class="form-input"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item :label-col="{span:companyModal.labelCol}" :wrapper-col="{span:companyModal.wrapperCol}" label="进场时间">
                            <a-date-picker class="form-date" v-decorator="['entranceTime',{rules:[{required:true,message:'请选择进场时间'}]}]" placeholder="请选择"></a-date-picker>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item :label-col="{span:companyModal.labelCol}" :wrapper-col="{span:companyModal.wrapperCol}" label="是否标记">
                            <a-select placeholder="请选择" v-decorator="['flag',{rules:[{required:true,message:'请选择是否标记'}]}]">
                                <a-select-option :value="1">是</a-select-option>
                                <a-select-option :value="0">否</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <!-- <a-col :span="24">
                        <a-form-item :label-col="{span:4}" :wrapper-col="{span:20}" label="上传附件"></a-form-item>
                    </a-col> -->
                </a-row>
                <a-row class="form-btn-group">
                    <a-button class="btn-save" @click="submit" :disabled="submitLoadding" :loading="submitLoadding" >
                        <a-icon v-if="!submitLoadding" type="save" />保存
                    </a-button>
                    <a-button class="btn-cancel" @click="companyModal.visible = false">取消</a-button>
                </a-row>
            </a-form>
        </a-modal>

        <!-- 参建单位退场 -->
        <a-modal centered width="660px" class="modal-container" :title="companyEditModal.title" v-model="companyEditModal.visible" :footer="false">
            <a-form :form="companyEditForm">
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="进场时间">
                   <a-date-picker disabled class="form-date" v-decorator="['entranceTime',{rules:[{required:true,message:'请选择进场时间'}]}]" placeholder="请选择"></a-date-picker>
               </a-form-item>
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="退场时间">
                   <a-date-picker disabled :disabledDate="disabledEndDate" class="form-date" v-decorator="['exitTime',{rules:[{required:true,message:'请选择进场时间'}]}]" placeholder="请选择"></a-date-picker>
               </a-form-item>
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="附件">
                   <a-row type="flex">
                        <a-row class="upload-preview-wrap">
                            <a v-for="(item,key) in fileLists" :key="key" class="file-item m-r-10">
                                <a-avatar  shape="square" :size="100" :src="item.url" />
                                <a class="close-icon" title="删除" @click="deleteFile(item)"><a-icon type="close"></a-icon></a>
                            </a>
                        </a-row>
                        <upload v-decorator="['files',{rules:[{required:true,message:'请上附件'}]}]" style="display:inline-block;width:100px" @func="getFiles" :node="{type:3,text:'上传'}" :accept="['.png','.jpg']"/>
                    </a-row>
               </a-form-item>
               <a-row class="form-btn-group">
                   <a-button class="btn-save" @click="projectCompanyExit"><a-icon type="save" />确定</a-button>
                   <a-button class="btn-cancel"  @click="companyEditModal.visible = false">取消</a-button>
               </a-row>
           </a-form>
        </a-modal>
    </section>
</template>

<script>
    import moment from 'moment'
    import empty from '@/components/empty'
    import upload from '@/components/upload'
    import validator from '@/until/validator'
    import previewImage from '@/components/previewImage'
    export default {
        data() {
            return {
                validator,
                total:0,
                pageNum:1,
                pageSize:3,
                companyLists: [],
                searchInfo:{},
                companyModal: {
                    visible: false,
                    title: "添加单位",
                    labelCol:8,
                    wrapperCol:15
                },
                companyEditModal:{
                    visible:false,
                    title:'参建单位退场'
                },
                companyDetailsModal:{
                    visible:false,
                    title:'查看详情'
                },
                companyDetails:{},
                companyId:'',
                curentCompany:{},
                companyTypeMap:[],
                fileLists:[],
                submitLoadding:false
            };
        },
        components:{empty,upload,previewImage},
        beforeCreate() {
            this.form = this.$form.createForm(this);
            this.companyEditForm = this.$form.createForm(this);
        },
        created(){
            this.queryTypeDropDownBox()
            this.queryCompanyList()
        },
        computed:{
            // submitLoadding(){
            //     return this.$store.state.submitLoadding
            // }
        },
        methods: {
            disabledEndDate(endValue) {
                const inDate = moment(new Date());
                if (!inDate || !endValue) return false;
                return inDate.valueOf() >= endValue.valueOf();
            },
            //查看详情
            showCompanyDetailsModal(list){
                this.companyDetailsModal.visible = true
                this.$api.queryCompanyDetails({id:list.id}).then(res =>{
                    if(res.code === 200){
                        this.companyDetails = Object.assign({},(res.data || {}),list)
                        this.companyDetails.files = this.companyDetails.files ? JSON.parse(this.companyDetails.files) : []
                    }
                })
            },
            showCompanyModal(list) {
                this.submitLoadding = false
                this.companyModal.visible = true;
                this.curentCompany = list || {}
                this.companyId = list ? list.id :''
                this.companyModal.title = this.companyId ? '编辑参建单位' : '新增参建单位'
                this.companyId && this.backfillForm(list)
                this.queryTypeDropDownBox()
                
            },
            //单位类型下拉
            queryTypeDropDownBox(){
                this.$api.queryTypeDropDownBox().then(res =>{
                    if(res.code === 200){
                        let list = res.data || []
                        let curentCompany = this.curentCompany
                        this.companyTypeMap = this.companyId ? list.concat({id:curentCompany.companyTypeId,companyTypeName :curentCompany.companyTypeName}) : list
                    }
                })
            },
            //编辑时回填字段
            backfillForm(list){
                let formData = {
                    companyName:list.companyName,
                    companyTypeId:list.companyTypeId,
                    registeredAmount:list.registeredAmount,
                    legalPerson:list.legalPerson,
                    socialCreditCode:list.socialCreditCode,
                    companyPhone:list.companyPhone,
                    manager:list.manager,
                    managerPhone:list.managerPhone,
                    companyAddress:list.companyAddress,
                    briefIntroduct:list.briefIntroduct,
                    entranceTime:list.entranceTime ? moment(list.entranceTime) : null,
                    flag:list.flag
                }
                this.$nextTick(() =>{
                    this.form.setFieldsValue(formData)
                })
            },
            getFiles(file){
                this.fileLists.push(file)
                this.$nextTick(() =>{
                    this.companyEditForm.setFieldsValue({
                        files:this.fileLists.length ? JSON.stringify(this.fileLists) : ''
                    })
                })
            },
            deleteFile(file){
                 this.fileLists = this.fileLists.filter(item => item.codeUrl != file.codeUrl)
                this.$nextTick(() =>{
                    this.companyEditForm.setFieldsValue({
                        files:this.fileLists.length ? JSON.stringify(this.fileLists) : ''
                    })
                })
            },
            //查询参建单位列表
            queryCompanyList(){
                let params = {...this.searchInfo}
                params.current = this.pageNum
                params.size = this.pageSize
                this.$api.queryCompanyList(params).then(res =>{
                    if(res.code === 200){
                        this.companyLists = (res.data && res.data.records) || []
                        this.total = (res.data && res.data.total) || 0
                    }
                })
            },
            changePageNum(p){
                this.pageNum = p
                this.queryCompanyList()
            },
            //重置搜索
            reset(){
                this.searchInfo = {}
                this.pageNum = 1
                this.queryCompanyList()
            },
            search(){
               
                this.pageNum = 1
                this.queryCompanyList()
            },
            //添加边界提交表单
            submit(){
                if(this.submitLoadding) return false
                this.form.validateFields((err,formData) =>{
                    if(!err){
                        //this.$store.commit('SET_SUBMITLOADDING',true)
                        
                        if(this.companyId){
                            formData.id = this.companyId
                        }
                        let params = {...formData}
                        params.entranceTime = formData.entranceTime ? formData.entranceTime.format('YYYY-MM-DD') : ''
                        let apiName = this.companyId ? 'updateProjectCompany' : 'insertProjectCompany'

                        
                        this.submitLoadding = true
                        this.$api[apiName](params).then(res =>{
                            if(res.code === 200){
                                this.companyModal.visible = false
                                this.queryCompanyList()
                            }
                        })
                    setTimeout(() => {
                        this.submitLoadding = false
                    }, 3000);
                    }
                   
                })
            },
            //删除
            deleteItem(list){
                this.$api.deleteProjectCompany({id:list.id}).then(res =>{
                    if(res.code === 200){
                        this.queryCompanyList()
                    }
                })
            },
            showCompanyEditModal(list){
                this.fileLists = []
                this.companyEditModal.visible = true;
                this.companyId = list.id

                this.$nextTick(() =>{
                    this.companyEditForm.setFieldsValue({
                        entranceTime:list.entranceTime ? moment(list.entranceTime) : null,
                        exitTime:moment(new Date())
                    })
                })
            },
            //参建单位退场
            projectCompanyExit(){
                this.companyEditForm.validateFields((err,formData) =>{
                    if(!err){
                        
                        formData.entranceTime = formData.entranceTime.format('YYYY-MM-DD')
                        formData.exitTime = formData.exitTime.format('YYYY-MM-DD')
                        formData.status = 2
                        formData.id = this.companyId
                        this.$api.projectCompanyExit(formData).then(res =>{
                            if(res.code === 200){
                                this.companyEditModal.visible = false
                                this.queryCompanyList()
                            }
                        })
                    }
                })
            }
        }
    };
</script>