<template>
    <section>
        <section class="search-container">
            <a-form layout="inline" class="m-t-14">
                <a-form-item label="单位名称">
                    <a-input class="search-input" placeholder="请输入" v-model="searchInfo.companyName" />
                </a-form-item>
                <a-form-item label="班组名称">
                    <a-input class="search-input" placeholder="请输入" v-model="searchInfo.teamName" />
                </a-form-item>
                <a-form-item label="班组组长">
                    <a-input class="search-input" placeholder="请输入" v-model="searchInfo.groupLeader"  />
                </a-form-item>
                <a-form-item label="状态">
                    <a-select class="search-select" placeholder="全部" v-model="searchInfo.status">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option value="1">在场 </a-select-option>
                        <a-select-option value="2">离场</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="标记">
                    <a-select class="search-select" placeholder="全部" v-model="searchInfo.flag">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option value="1">已标记 </a-select-option>
                        <a-select-option value="2">未标记</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" class="m-r-8" @click="search"><a-icon type="search" />查询</a-button>
                    <a-button @click="reset"><a-icon type="reload" />重置</a-button>
                </a-form-item>
            </a-form>
            <a-row class="right-action-panel m-t-14">
                <a-button type="primary" @click="showTeamModal" class="item">添加班组</a-button>
            </a-row>
        </section>


        <section class="list-single-container" v-if="teamLists.length">
            <a-row class="list-border list-item m-t-18" v-for="(item,ind) in teamLists" :key="ind">
                <a-row class="action-btn-group">
                    <a-row>
                        <a-button size="small" class="m-b-8" @click="showTeamDetailsModal(item)">查看</a-button>
                        <a-button v-if="item.status == 1" size="small" class="m-b-8" @click="showTeamEditModal(item)">退场</a-button>
                        <a-button v-if="item.status == 1" size="small" class="m-b-8" @click="showTeamModal(item)">编辑</a-button>
                        <a-popconfirm v-if="item.status == 2"  title="确定删除该班组?" @confirm="deleteItem(item)"><a-button size="small">删除</a-button></a-popconfirm>
                        
                    </a-row>
                </a-row>
                <p>{{item.companyName}}</p>
                <a-row class="m-b-10">
                    <a-col :span="6">班组名称：{{item.teamName}}</a-col>
                    <a-col :span="6">入场时间： {{item.admissionDate}}</a-col>
                    <a-col :span="6">班组状态：<span :style="{color:item.status == 1 ? '#52C41A' :'#FF555E'}">{{item.status == 1? '在场' : item.status == 2 ? '离场' : ''}}</span></a-col>
                </a-row>
                <a-row class="m-b-10">
                    <a-col :span="6">班组组长：{{item.groupLeader}}</a-col>
                    <a-col :span="6">离场时间： {{item.leaveDate}}</a-col>
                </a-row>
                <a-row>
                    <a-col :span="6">组长电话：{{item.phone}}</a-col>
                    <a-col :span="6">是否标记： {{item.flag == 1 ? '是' : '否'}}</a-col>
                    
                </a-row>
            </a-row>
        </section>
        <empty v-else :height="430" />
        <section class="pagination-container">
            <a-pagination 
            size="default" :pageSize="pageSize" :current="pageNum" 
            :total="total" :hideOnSinglePage="true" 
            :show-total="(total) => `共${total}条`"
            :showQuickJumper="true"
		    :showSizeChanger="false"
            @change="changePageNum" />
        </section>
        <!-- a-modal -->
        <!-- 查看班组详情 -->
        <a-modal centered width="1080px" class="modal-container" :title="teamDetailsModal.title" v-model="teamDetailsModal.visible" :footer="false" :destroyOnClose="true">
           <section class="info-container">
               <section class="info-item-wrap">
                   <p class="info-title">班组信息</p>
                   <a-row class="m-t-18">
                        <a-col :span="8">
                           <a-row class="info-item">
                               <a-col class="key">班组名称</a-col>
                               <a-col class="val">{{teamDetails.teamName}}</a-col>
                           </a-row>
                       </a-col>
                       <a-col :span="8">
                           <a-row class="info-item">
                               <a-col class="key">所属单位</a-col>
                               <a-col class="val">{{teamDetails.companyName}}</a-col>
                           </a-row>
                       </a-col>
                       <a-col :span="8">
                           <a-row class="info-item">
                               <a-col class="key">单位类型</a-col>
                               <a-col class="val">{{teamDetails.companyTypeName}}</a-col>
                           </a-row>
                       </a-col>
                      
                   </a-row>
                   <a-row>
                       <a-col :span="8">
                           <a-row class="info-item">
                               <a-col class="key">进场时间</a-col>
                               <a-col class="val">{{teamDetails.admissionDate}}</a-col>
                           </a-row>
                       </a-col>
                       <a-col :span="8">
                           <a-row class="info-item">
                               <a-col class="key">离场时间</a-col>
                               <a-col class="val">{{teamDetails.leaveDate || '-'}}</a-col>
                           </a-row>
                       </a-col>
                       <a-col :span="8">
                           <a-row class="info-item">
                               <a-col class="key">班组状态</a-col>
                               <a-col class="val" :style="{color:teamDetails.status == 1 ? '#52C41A' :'#FF555E'}">{{teamDetails.status == 1? '在场' : teamDetails.status == 2 ? '离场' : ''}}</a-col>
                           </a-row>
                       </a-col>
                   </a-row>
                   <a-row>
                       <a-col :span="8">
                           <a-row class="info-item">
                               <a-col class="key">班组组长</a-col>
                               <a-col class="val">{{teamDetails.groupLeader}}</a-col>
                           </a-row>
                       </a-col>
                       <a-col :span="8">
                           <a-row class="info-item">
                               <a-col class="key">联系电话</a-col>
                               <a-col class="val">{{teamDetails.phone}}</a-col>
                           </a-row>
                       </a-col>
                   </a-row>
                   <a-row>
                       <a-col :span="8">
                           <a-row class="info-item">
                               <a-col class="key">创建人</a-col>
                               <a-col class="val">{{teamDetails.userName}}</a-col>
                           </a-row>
                       </a-col>
                       <a-col :span="8">
                           <a-row class="info-item">
                               <a-col class="key">创建时间</a-col>
                               <a-col class="val">{{teamDetails.createTime}}</a-col>
                           </a-row>
                       </a-col>
                   </a-row>
                   <a-row>
                       <a-col :span="16">
                           <a-row class="info-item">
                               <a-col class="key">班组描述</a-col>
                               <a-col class="val">{{teamDetails.remarks}}</a-col>
                           </a-row>
                       </a-col>
                   </a-row>
                   <a-row v-if="teamDetails.teamFile && teamDetails.teamFile.length">
                        <a-col :span="16">
                           <a-row class="info-item">
                               <a-col class="key">离场附件</a-col>
                                <a-col class="val upload-preview-wrap">
                                    <a class="file-item m-r-10" v-for="(item,ind) in teamDetails.teamFile" :key="ind">
                                        <preview-image :data="item.codeUrl" :size="100" />
                                    </a>
                                </a-col>
                           </a-row>
                       </a-col>
                   </a-row>
               </section>
               <section class="info-item-wrap">
                   <p class="info-title">班组总人数({{teamDetailsModal.list.length}})</p>
                   <section class="list-table-container">
                       <a-table size="small" :columns="teamDetailsModal.columns" :data-source="teamDetailsModal.list" :pagination="{total:teamDetailsModal.list.length,hideOnSinglePage:true,current:pageNum}" />  
                   </section>
               </section>
           </section>
        </a-modal>
        <!-- 新增和编辑班组 -->
        <a-modal centered width="860px" class="modal-container" :title="teamModal.title" v-model="teamModal.visible" :footer="false" :destroyOnClose="true">
           <a-form :form="form">

               <a-row :gutter="20">
                   <a-col :span="12">
                        <a-form-item :label-col="{span:6}" :wrapper-col="{span:18}" label="单位名称" >
                            <a-select showSearch option-filter-prop="children" placeholder="请选择" v-decorator="['companyId',{rules:[{required:true,message:'请选择单位'}]}]" @change="e => changeCompany(e)">
                                <a-select-option v-for="item in companyMap" :key="item.id" :value="item.id">{{item.companyName}}</a-select-option>
                            </a-select>
                        </a-form-item>
                   </a-col>
                   <a-col :span="12">
                       <a-form-item :label-col="{span:6}" :wrapper-col="{span:18}" label="单位类型">
                            <a-select disabled placeholder="请选择" v-decorator="['companyTypeId',{rules:[{required:true,message:'单位类型不能为空'}]}]">
                                <a-select-option v-for="item in companyTypeMap" :key="item.id" :value="item.id">{{item.companyTypeName}}</a-select-option>
                            </a-select>
                        </a-form-item>
                   </a-col>
                   <a-col :span="12">
                        <a-form-item :label-col="{span:6}" :wrapper-col="{span:18}" label="班组名称">
                            <a-input placeholder="请输入" v-decorator="['teamName',{rules:[{required:true,message:'班组名称不能为空'}]}]" class="form-input"></a-input>
                        </a-form-item>
                   </a-col>
                   <a-col :span="12">
                        <a-form-item :label-col="{span:6}" :wrapper-col="{span:18}" label="班组组长">
                            <a-input placeholder="请输入" v-decorator="['groupLeader',{rules:[{required:true,message:'班组组长不能为空'}]}]" class="form-input"></a-input>
                        </a-form-item>
                   </a-col>
                   <a-col :span="12">
                         <a-form-item :label-col="{span:6}" :wrapper-col="{span:18}" label="联系方式">
                            <a-input placeholder="请输入" v-decorator="['phone',{rules:[{required:true,message:'联系方式不能为空'}]}]" class="form-input"></a-input>
                        </a-form-item>
                   </a-col>
                   <a-col :span="12">
                         <a-form-item :label-col="{span:6}" :wrapper-col="{span:18}" label="进场时间">
                            <a-date-picker class="form-date" v-decorator="['admissionDate',{rules:[{required:true,message:'请选择进场时间'}]}]" placeholder="请选择"></a-date-picker>
                        </a-form-item>
                   </a-col>
                   <a-col :span="12">
                         <a-form-item :label-col="{span:6}" :wrapper-col="{span:18}" label="是否标记">
                            <a-select placeholder="请选择" v-decorator="['flag',{rules:[{required:true,message:'请选择是否标记'}]}]">
                                <a-select-option :value="1">是</a-select-option>
                                <a-select-option :value="0">否</a-select-option>
                            </a-select>
                        </a-form-item>
                   </a-col>
                   <a-col :span="24">
                         <a-form-item :label-col="{span:3}" :wrapper-col="{span:21}" label="班组描述">
                            <a-textarea placeholder="班组描述" rows="4" v-decorator="['remarks',{rules:[{required:true,message:'班组描述不能为空'}]}]"></a-textarea>
                        </a-form-item>
                   </a-col>
               </a-row>
               <a-row class="form-btn-group">
                   <a-button class="btn-save" :disabled="submitLoadding" :loading="submitLoadding" @click="submit"><a-icon v-if="!submitLoadding" type="save" />保存</a-button>
                   <a-button class="btn-cancel"  @click="teamModal.visible = false">取消</a-button>
               </a-row>
           </a-form>
        </a-modal>
        <!-- 班组退场 -->
        <a-modal centered width="660px" class="modal-container" :title="teamEditModal.title" v-model="teamEditModal.visible" :footer="false">
            <a-form :form="teamEditForm">
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="进场时间">
                   <a-date-picker disabled class="form-date" v-decorator="['admissionDate',{rules:[{required:true,message:'请选择进场时间'}]}]" placeholder="请选择"></a-date-picker>
               </a-form-item>
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="退场时间">
                   <a-date-picker class="form-date" disabled v-decorator="['leaveDate',{rules:[{required:true,message:'请选择进场时间'}]}]" placeholder="请选择"></a-date-picker>
               </a-form-item>
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="附件">
                   <a-row type="flex">
                        <a-row class="upload-preview-wrap">
                            <a v-for="(item,key) in fileLists" :key="key" class="file-item m-r-10">
                                <a-avatar  shape="square" :size="100" :src="item.url" />
                                <a class="close-icon" title="删除" @click="deleteFile(item)"><a-icon type="close"></a-icon></a>
                            </a>
                        </a-row>
                        <upload v-decorator="['teamFile',{rules:[{required:true,message:'请上附件'}]}]" style="display:inline-block;width:100px" @func="getFiles" :node="{type:3,text:'上传'}" :accept="['.png','.jpg']"/>
                    </a-row>
               </a-form-item>
               <a-row class="form-btn-group">
                   <a-button class="btn-save"  @click="projectTeamExit"><a-icon type="save" />确定</a-button>
                   <a-button class="btn-cancel"  @click="teamEditModal.visible = false">取消</a-button>
               </a-row>
           </a-form>
        </a-modal>
    </section>
</template>

<script>
const columns = [
    {title:'序号',dataIndex:'key',align:'center',key:'key'},
    {title:'所属单位',dataIndex:'companyName',align:'center',key:'companyName'},
    {title:'姓名',dataIndex:'personnelName',align:'center',key:'personnelName'},
    {title:'工种',dataIndex:'typeName',align:'center',key:'typeName'},
    {title:'联系方式',dataIndex:'phoneNumber',align:'center',key:'phoneNumber'},
]
import moment from 'moment'
import empty from '@/components/empty'
import upload from '@/components/upload'
 import previewImage from '@/components/previewImage'
export default {
    data(){
        return{
            total:0,
            pageNum:1,
            pageSize:3,
            teamLists:[],
            searchInfo:{},
            teamModal:{
                visible:false,
                title:'添加班组'
            },
            teamEditModal:{
                visible:false,
                title:'班组退场'
            },
            teamDetailsModal:{
                title:'查看班组',
                visible:false,
                columns:columns,
                list:[]
            },
            teamId:'',
            companyMap:[],
            companyTypeMap:[],
            teamDetails:{},
            fileLists:[],
            submitLoadding:false
            
        }
    },
    components:{empty,upload,previewImage},
    beforeCreate(){
        this.form = this.$form.createForm(this)
        this.teamEditForm = this.$form.createForm(this)
    },
    
    created(){
        this.$store.commit('SET_SUBMITLOADDING',false)
        this.queryTeamList()
        this.queryTypeDropDownBox()
        this.queryCompanyDropDownBox()
    },
    methods:{
        //单位类型下拉
        queryTypeDropDownBox(){
            this.$api.queryTypeDropDownBox().then(res =>{
                if(res.code === 200){
                    this.companyTypeMap = res.data || []
                }
            })
        },
        //单位下拉
        queryCompanyDropDownBox(){
            this.$api.queryCompanyDropDownBox().then(res =>{
                if(res.code === 200){
                    this.companyMap = res.data || []
                }
            })
        },
        changeCompany(companyId){
           let company =  this.companyMap.filter(item => item.id === companyId)[0]
           if(company){
               this.$nextTick(() =>{
                   this.form.setFieldsValue({
                       companyTypeId:company.companyTypeId
                   })
               })
           }
        },
        showTeamDetailsModal(list){
            this.teamDetailsModal.visible = true
            this.$api.queryTeamDetails({id:list.id}).then(res =>{
                if(res.code === 200){
                    this.teamDetails = (res.data && res.data.team) || {}
                    this.teamDetails.teamFile = this.teamDetails.teamFile ? JSON.parse(this.teamDetails.teamFile) : []
                    this.teamDetailsModal.list = (res.data && res.data.teamPersonnel) || []
                    this.teamDetailsModal.list.forEach((item,ind) =>{
                        item.key = ind +1
                    })
                }
            })
        },
        showTeamModal(list){
            this.teamModal.visible = true
            this.submitLoadding = false
            this.teamId = list ? list.id :''
            this.teamModal.title = this.teamId ? '编辑项目班组' : '新增项目班组'
            this.teamId && this.backfillForm(list)
        },
        //编辑回填字段
        backfillForm(list){
            let company = this.companyMap.filter(item => item.id === list.companyId)[0]
            let formData ={
                admissionDate:list.admissionDate ? moment(list.admissionDate) : null,
                companyId:list.companyId,
                teamName:list.teamName,
                flag:list.flag,
                remarks:list.remarks,
                groupLeader:list.groupLeader,
                phone:list.phone
            }
            formData.companyTypeId = company ? company.companyTypeId : ''
            this.$nextTick(() =>{
                this.form.setFieldsValue(formData)
            })
        },
        //查询班组列表
        queryTeamList(){
            let params = {...this.searchInfo}
            params.size = this.pageSize
            params.current = this.pageNum
            this.$api.queryTeamList(params).then(res =>{
                if(res.code === 200){
                    this.teamLists = (res.data && res.data.records) || []
                    this.total = (res.data && res.data.total) || 0
                }
            })
        },
        changePageNum(p){
            this.pageNum = p
            this.queryTeamList()
        },
        //重置
        reset(){
            this.searchInfo = {}
            this.pageNum = 1
            this.queryTeamList()
        },
        search(){
            this.pageNum = 1
            this.queryTeamList()
        },
        //新增和编辑时提交表单
        submit(){
            if(this.submitLoadding) return false
            this.submitLoadding = true
            this.form.validateFields((err,formData) =>{
                if(!err){
                    formData.admissionDate = formData.admissionDate.format('YYYY-MM-DD')
                    if(this.teamId){
                        formData.id = this.teamId
                    }
                    let apiName = this.teamId ? 'updateProjectTeam' : 'insertProjectTeam'
                    this.$api[apiName](formData).then(res =>{
                        if(res.code === 200){
                            this.teamModal.visible = false
                            this.queryTeamList()
                        }
                    })
                }
                setTimeout(() => {
                    this.submitLoadding = false
                }, 2000);
            })
        },
        //删除
        deleteItem(list){
            this.$api.deleteProjectTeam({id:list.id}).then(res =>{
                if(res.code === 200){
                    this.queryTeamList()
                }
            })
        },
        //退场modal
        showTeamEditModal(list){
            this.teamEditModal.visible = true
            this.teamId = list.id
            this.$nextTick(() =>{
                this.teamEditForm.setFieldsValue({
                    admissionDate:list.admissionDate ? moment(list.admissionDate) : null,
                    leaveDate:moment(new Date())
                })
            })
        },
        getFiles(file){
                this.fileLists.push(file)
                this.$nextTick(() =>{
                    this.teamEditForm.setFieldsValue({
                        teamFile:this.fileLists.length ? JSON.stringify(this.fileLists) : ''
                    })
                })
            },
            deleteFile(file){
                 this.fileLists = this.fileLists.filter(item => item.codeUrl != file.codeUrl)
                this.$nextTick(() =>{
                    this.teamEditForm.setFieldsValue({
                        teamFile:this.fileLists.length ? JSON.stringify(this.fileLists) : ''
                    })
                })
            },
        //班组退场
        projectTeamExit(){
            this.teamEditForm.validateFields((err,formData) =>{
                if(!err){
                    let params = {
                        leaveDate:formData.leaveDate.format('YYYY-MM-DD'),
                        teamFile:formData.teamFile,
                        id:this.teamId,
                        status:2
                    }
                    this.$api.projectTeamExit(params).then(res =>{
                        if(res.code === 200){
                            this.teamEditModal.visible = false
                            this.queryTeamList()
                        }
                    })
                }
            })
        }
    }
}
</script>