<template>
    <section>
        <section class="info-container" v-if="projectInfo.id">
            <!--  -->
            <section class="info-item-wrap">
                <p class="info-title">
                    <span>基础信息</span>
                    <router-link class="f-right" :to="{path:'/pims/projectPlan/info/edit',query:{id:projectInfo.id}}"><a-button type="primary" size="small" style="height:28px"><a-icon type="edit" />编辑</a-button></router-link>
                </p>
                <a-row class="p-t-18">
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">项目名称</a-col>
                            <a-col class="val">{{projectInfo.projectName}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">施工许可证</a-col>
                            <a-col class="val">{{projectInfo.licenseNo}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">GIS经纬度</a-col>
                            <a-col class="val">{{projectInfo.gisX}},{{projectInfo.gisY}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">项目所在地</a-col>
                            <a-col class="val">{{projectInfo.projectSite}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">详细地址</a-col>
                            <a-col class="val">{{projectInfo.projectAddress}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">建设面积(万平方米)</a-col>
                            <a-col class="val">{{projectInfo.constructionArea}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">市合同备案编号</a-col>
                            <a-col class="val">{{projectInfo.contractNo}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">合同金额(万元)</a-col>
                            <a-col class="val">{{projectInfo.contractAmount}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">预付款金额(万元)</a-col>
                            <a-col class="val">{{projectInfo.advancePayment}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">清单人工费(万元)</a-col>
                            <a-col class="val">{{projectInfo.laborCost}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">安全文明施工措施费金额(万元)</a-col>
                            <a-col class="val">{{projectInfo.saftyCost}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">项目性质</a-col>
                            <a-col class="val">{{projectInfo.projectNature == 1 ? '房建' : projectInfo.projectNature == 2 ? '市政' : '轨道'}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">开工日期</a-col>
                            <a-col class="val">{{projectInfo.startDate}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">计划竣工日期</a-col>
                            <a-col class="val">{{projectInfo.endDate}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">实际竣工日期</a-col>
                            <a-col class="val">{{projectInfo.actualDate}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">所属住建局</a-col>
                            <a-col class="val">{{projectInfo.thirdPartyName}}</a-col>
                        </a-row>
                    </a-col>
                </a-row>
            </section>

            <section class="info-item-wrap m-t-18">
                <p class="info-title"> 项目地图GIS</p>
                <section class="map-contanier">
                    <baidu-map
                        style="height:100%;width:100%"
                        :center="{lng:projectInfo.gisX,lat:projectInfo.gisY}"
                        :zoom="15"
                        :scroll-wheel-zoom="true"
                        ak="0FuoX30MFf7YMrdS5Wi9GGAcHBblKDuu">
                        <bm-geolocation
                            anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                            :showAddressBar="true"
                            :autoLocation="true"
                        ></bm-geolocation>
                    <bm-marker
                            size="90"
                            animation="BMAP_ANIMATION_BOUNCE"
                            :position="{lng: projectInfo.gisX, lat: projectInfo.gisY}"
                            :dragging="true"
                    ></bm-marker>
                </baidu-map>
            </section>
            </section>
        </section>
        <a-row v-else type="flex" justify="center" align="middle" style="height:500px" >
            <router-link class="f-right" :to="{path:'/pims/projectPlan/info/edit'}"><a-button type="primary">新增项目</a-button></router-link>
        </a-row>
    </section>
</template>

<script>
import {BaiduMap, BmGeolocation, BmMarker} from "vue-baidu-map";
export default {
    data(){
        return{
            projectInfo:{}
        }
    },
    components:{
        BaiduMap, BmGeolocation, BmMarker
    },
    created(){
        this.queryProject()
    },
    methods:{
        queryProject(){
            this.$api.queryProject().then(res =>{
                if(res.code === 200){
                    this.projectInfo = res.data || {}
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .info-container .info-item-wrap .info-item .key{
        width:300px;
    }
    .map-contanier{
        height: 500px;
        padding: 0 4px;
    }
</style>