<template>
    <section>
        <section class="search-container">
            <a-form layout="inline" class="m-t-14">
                <a-form-item label="单位名称" >
                    <a-select showSearch option-filter-prop="children" class="search-select" v-model="searchInfo.companyId" placeholder="全部">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option v-for="item in companyMap" :key="item.id" :value="item.id">{{item.companyName}}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="人员姓名">
                    <a-input class="search-input" placeholder="请输入" v-model="searchInfo.name" />
                </a-form-item>
                <a-form-item label="手机号码">
                    <a-input class="search-input" placeholder="请输入" v-model="searchInfo.phone" />
                </a-form-item>
                <a-form-item label="职务">
                    <a-select class="search-select" placeholder="全部" v-model="searchInfo.jobsId">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option v-for="item in jobsMap" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="状态">
                    <a-select class="search-select" placeholder="全部" v-model="searchInfo.status">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option value="0">启用</a-select-option>
                        <a-select-option value="1">禁用</a-select-option>
                    </a-select>
                </a-form-item>
                <!-- <a-form-item label="角色名称">
                    <a-select class="search-select" placeholder="请选择" v-model="searchInfo.roleId"></a-select>
                </a-form-item> -->
                <a-form-item>
                    <a-button class="m-r-8" @click="search" type="primary"><a-icon type="search" />查询</a-button>
                    <a-button @click="reset"><a-icon type="reload" />重置</a-button>
                </a-form-item>
            </a-form>
            <a-row class="right-action-panel m-t-14">
                <a-button type="primary" @click="showAccountModal(null)" class="item">添加账号</a-button>
            </a-row>
        </section>

        <section class="list-table-container m-t-18">
            <a-table size="small" :columns="columns" :dataSource="userLists" :pagination="{total,pageSize,hideOnSinglePage:true,showTotal:(total) => `共${total}条`,size:'default',showQuickJumper:true,showSizeChanger:false}" @change="changePageNum">
                <!-- 状态 -->
                <span slot="status" :style="{color:text == 1 ? '#FF555E' :'#52C41A'}" slot-scope="text">{{text == 1 ? '禁用' : '启用'}}</span>
                <!-- 操作 -->
                <a slot="action" slot-scope="text,list">
                    <a class="m-r-8" @click="showAccountModal(list)">编辑</a>
                    <a-popconfirm v-if="list.isFreeze == 1" class="m-r-8" @confirm="changeIsFreeze(list)" :title="'确定解封该账号?'">解封</a-popconfirm>
                    <a-popconfirm class="m-r-8" @confirm="changeUserStatus(list)" :title="'确定'+(list.status == 1 ? '启用' : '禁用')+'该账号?'">{{list.status == 1 ? '启用' : '禁用'}}</a-popconfirm>
                    <a-popconfirm class="m-r-8" title="确定删除该账号?" @confirm="deleteItem(list)">删除</a-popconfirm>
                </a>
            </a-table>
        </section>

        <!-- modal -->
        <a-modal centered width="660px" class="modal-container" :title="accountModal.title" v-model="accountModal.visible" :footer="false" :destroyOnClose="true">
            
           <a-form :form="form">
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="单位名称">
                   <a-select showSearch option-filter-prop="children" placeholder="请选择" v-decorator="['companyId',{rules:[{required:true,message:'请选择单位'}]}]" @change="e => changeCompany(e)">
                       <a-select-option v-for="item in companyMap" :key="item.id" :value="item.id">{{item.companyName}}</a-select-option>
                   </a-select>
               </a-form-item>
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="姓名" :has-feedback="!!personnelId" validate-status="success">
                  
                       <a-auto-complete placeholder="请输入" @select="selectPerson" @search="searchPerson" v-decorator="['name',{rules:[{required:true,message:'姓名不能为空'}]}]" >
                       <template slot="dataSource">
                           <a-select-option v-for="item in personnelLists" :key="item.id" :text="item.personnelName">
                               {{item.personnelName}}
                           </a-select-option>
                       </template>
                   </a-auto-complete>
                  
                   
               </a-form-item>
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="手机号码">
                   <a-input placeholder="账号密码为空，默认手机号后六位" v-decorator="['phone',{rules:[{required:true,message:'手机号码不能为空'},{validator:validator.phoneReg}]}]" class="form-input"></a-input>
               </a-form-item>
              <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="密码">
                   <a-input placeholder="默认手机号后六位" v-decorator="['password',{rules:[{required:false,message:'密码不能为空'}]}]" class="form-input"></a-input>
               </a-form-item>
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="职务">
                   <a-select showSearch option-filter-prop="children" placeholder="请选择" v-decorator="['jobsId',{rules:[{required:true,message:'请选择职务'}]}]">
                       <a-select-option v-for="(item,key) in jobsMap" :key="key" :value="item.id">{{item.name}}</a-select-option>
                   </a-select>
               </a-form-item>
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="角色名称">
                   <a-select placeholder="请选择" v-decorator="['roles',{rules:[{required:true,message:'请选择角色'}]}]">
                       <a-select-option v-for="(item,key) in roleLists" :key="key" :value="item.id">{{item.name}}</a-select-option>
                   </a-select>
               </a-form-item>
               
               <a-row class="form-btn-group">
                   <a-button class="btn-save" :disabled="submitLoadding" :loadding="submitLoadding"  @click="submit"><a-icon v-if="!submitLoadding" type="save" />保存</a-button>
                   <a-button class="btn-cancel"  @click="accountModal.visible = false">取消</a-button>
               </a-row>
           </a-form>

        </a-modal>
    </section>
</template>


<script>
const columns = [
    {title:'序号',dataIndex:'key',align:'center',key:'key',width:80},
    {title:'单位名称',dataIndex:'companyName',align:'center',key:'companyName'},
    {title:'人员姓名',dataIndex:'name',align:'center',key:'name'},
    {title:'手机号码',dataIndex:'phone',align:'center',key:'phone'},
    {title:'职务',dataIndex:'jobsName',align:'center',key:'jobsName'},
    {title:'状态',dataIndex:'status',scopedSlots:{customRender: "status"},align:'center',key:'status'},
    {title:'操作',scopedSlots:{customRender: "action"},align:'center'},
]
import validator from '@/until/validator'
export default {
    data(){
        return{
            columns,
            total:0,
            pageNum:1,
            pageSize:9,
            userLists:[], //账号列表

            searchInfo:{},
            accountModal:{
                visible:false,
                title:'添加账号'
            },
            personnelLists:[],    //人员列表
            personnelId:'',
            personnelName:'',
            userId:'',
            companyMap:[],
            companyId:'',
            jobsMap:[],
            roleLists:[],
            validator
        }
    },
    beforeCreate(){
        this.form = this.$form.createForm(this)
    },
    created(){
        this.queryCompanyDropDownBox()
        this.queryJobsDropDownBox()
        
        this.queryRoleList()
        this.queryUserList()
        this.queryPersonnelOptionsList()
    },
    computed:{
        submitLoadding(){
            return this.$store.state.submitLoadding
        }
    },
    methods:{
        //职务工种
        queryJobsDropDownBox(){
            let type = 2
            this.$api.queryJobsDropDownBox({type}).then(res =>{
                if(res.code === 200){
                    this.jobsMap = res.data || []
                }
            })
        },
        //单位下拉
        queryCompanyDropDownBox(){
            this.$api.queryCompanyDropDownBox().then(res =>{
                if(res.code === 200){
                    this.companyMap = res.data || []
                }
            })
        },
        changeCompany(companyId){
            this.companyId = companyId
            this.queryPersonnelOptionsList()
            this.$nextTick(() =>{
                 this.form.setFieldsValue({
                        jobsId:'',
                    })
                    this.personnelId = ''
                    this.personnelName = ''
            })
            
        },
        queryPersonnelOptionsList(){
            this.$api.queryPersonnelOptionsList({companyId:this.companyId,personnelName:this.personnelName}).then(res =>{
                if(res.code === 200){
                    let personnelLists = res.data  || []
                    //人员类型为1（管理人员）和2（劳务管理人员）才能关联账号
                    personnelLists = personnelLists.filter(item => item.personnelType != 3)
                    personnelLists.forEach(item =>{
                        item.key = item.personnelName
                        item.value = item.id
                        item.label=item.personnelName
                        
                    })
                    this.personnelLists = personnelLists

                    //标记
                    let personInfo = this.personnelLists.filter(item => item.personnelName === this.personnelName)[0] || {}
                    this.personnelId = personInfo.id ? personInfo.id : ''
                    
                }
            })
        },
        searchPerson(personnelName){
            this.personnelName = personnelName
            this.queryPersonnelOptionsList() 
        },
        selectPerson(personnelId){
            let personnelInfo = this.personnelLists.filter(item => item.id === personnelId)[0] || {}
            this.personnelId = personnelInfo.id || ''
         
            if(this.personnelId){
                let formData = {
                    name:personnelInfo.personnelName,
                    phone:personnelInfo.phoneNumber
                }
                if(personnelInfo.personnelType != 3){
                    formData.jobsId = personnelInfo.jobId
                }
                this.$nextTick(() =>{
                    this.form.setFieldsValue(formData)
                })
            }
            

        },
        queryRoleList(){
            this.$api.queryRoleList({size:1000,status:1}).then(res =>{
                if(res.code === 200){
                    this.roleLists = (res.data && res.data.records) || []
                }
            })
        },
        showAccountModal(list){
            this.accountModal.visible = true
            
            this.userId = list ? list.id : ''
            this.accountModal.title = this.userId ? '编辑账号' : '新增账号'
            this.userId && this.queryUserDetails(this.userId)
            this.userId && this.queryPersonnelOptionsList()
        },
        queryUserDetails(id){
            this.$api.queryUserDetails({id}).then(res =>{
                if(res.code === 200){
                    let info = res.data || {}
                    let formData = {
                        companyId:info.company_id,
                        jobsId:info.jobs_id,
                        name:info.name,
                        phone:info.phone,
                        roles:info.roles
                    }
                    this.form.setFieldsValue(formData)
                }
            })
        },
        //查询账号列表
        queryUserList(){
            let params = {
                current:this.pageNum,
                size:this.pageSize,
                companyName:this.searchInfo.companyName,
                jobsId:this.searchInfo.jobsId,
                name:this.searchInfo.name,
                phone:this.searchInfo.phone,
                roleId:this.searchInfo.roleId,
                status:this.searchInfo.status,
            }
            this.$api.queryUserList(params).then(res =>{
                if(res.code === 200){
                    this.userLists = (res.data && res.data.records) || []
                    this.userLists.forEach((item,ind) =>{
                        item.key = (this.pageSize*(this.pageNum - 1))+ind+1
                    })
                    this.total = (res.data && res.data.total) || 0
                }
            })
        },
        //分页
        changePageNum(p){
            this.pageNum = p.current
            this.queryUserList()
        },
        //提交表单数据（添加、编辑）
        submit(){
            this.form.validateFields((err,formData) =>{
                if(!err){
                    this.$store.commit('SET_SUBMITLOADDING',true)
                    let params = {
                        roles:formData.roles,
                        user:{
                            companyId:formData.companyId,
                            jobsId:formData.jobsId,
                            name:formData.name,
                            phone:formData.phone,
                        }
                    }
                    if(this.personnelId){
                        params.personnelId = this.personnelId
                    }
                    if(this.userId){
                        params.user.id = this.userId
                        if(formData.password){
                            params.user.password = formData.password
                        }
                    }else{
                        params.user.password = formData.password ? formData.password : formData.phone.substr(formData.phone.length-6,6) 
                    }
                  
                   
                    let apiName = this.userId ? 'updateUser' : 'insertUser'
                    this.$api[apiName](params).then(res =>{
                        if(res.code === 200){
                            this.accountModal.visible = false
                            this.queryUserList()
                        }else {
                            this.$message.error(res.msg);
                        }
                    })
                }
                
            })
        },
        reset(){
            this.searchInfo = {}
            this.pageNum = 1
            this.queryUserList()
        },
        search(){
            this.pageNum = 1
            this.queryUserList()
        },
        //删除
        deleteItem(list){
            this.$api.deleteUser({id:list.id}).then(res =>{
                if(res.code === 200){
                    this.queryUserList()
                }
            })
        },
        //启用禁用
        changeUserStatus(list){
            let status = list.status == 1 ? 0 : 1
            this.$api.changeUserStatus({id:list.id,status}).then(res =>{
                if(res.code === 200){
                    this.queryUserList()
                }
            })
        },
        changeIsFreeze(list){
            let isFreeze = 0
            this.$api.changeUserStatus({id:list.id,isFreeze}).then(res =>{
                if(res.code === 200){
                    this.queryUserList()
                }
            })
        }
    }
}
</script>