<template>
    <section>
        <section class="search-container">
            <a-form layout="inline" class="m-t-14">
                <a-form-item label="单位类型" >
                    <a-select class="search-select" placeholder="全部" v-model="searchInfo.companyTypeId">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option v-for="item in companyTypeMap" :key="item.id" :value="item.id">{{item.companyTypeName}}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="工种名称">
                    <a-input class="search-input" placeholder="请输入" v-model="searchInfo.name" />
                </a-form-item>
                
                <a-form-item label="状态">
                    <a-select class="search-select" placeholder="全部" v-model="searchInfo.status">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option value="1">启用</a-select-option>
                        <a-select-option value="2">禁用</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" class="m-r-8" @click="search"><a-icon type="search" />查询</a-button>
                    <a-button @click="reset"><a-icon type="reload" />重置</a-button>
                </a-form-item>
            </a-form>
            <a-row class="right-action-panel m-t-14">
                <a-button type="primary" @click="showWorkTypeModal(null)" class="item">添加工种</a-button>
            </a-row>
        </section>
        <!-- table 列表 -->
        <section class="list-table-container m-t-18">
            <a-table size="small" :columns="columns" :data-source="workTypeLists" :pagination="{total,pageSize,hideOnSinglePage:true,showTotal:(total) => `共${total}条`,size:'default',showQuickJumper:true,showSizeChanger:false}" @change="changePageNum">
                <!-- 是否为特殊工种 -->
                <span slot="isSpecial" slot-scope="text">{{text == 2 ? '是' : '否'}}</span>
                <!-- 状态 -->
                <span slot="status" :style="{color:text == 1 ? '#52C41A' :'#FF555E'}" slot-scope="text">{{text == 1 ? '启用' : '禁用'}}</span>
                <!-- 操作 -->
                <a slot="action" slot-scope="text,list"  v-if="list.type == 1">
                    <a @click="showWorkTypeModal(list)">编辑</a>
                    <a-divider style="color:#222" type="vertical" />
                    <a-popconfirm @confirm="changeStatus(list)" :title="'确定'+(list.status == 1 ? '禁用' : '启用')+'该工种?'">{{list.status == 1 ? '禁用' : '启用'}}</a-popconfirm>
                    <a-divider style="color:#222" type="vertical" />
                    <a-popconfirm title="确定删除该工种?" @confirm="deleteItem(list)">删除</a-popconfirm>
                </a>
                <span v-else slot="action">-</span>
            </a-table>
        </section>


        <!-- modal -->
        <a-modal centered width="660px" class="modal-container" :title="workTypeModal.title" v-model="workTypeModal.visible" :footer="false" :destroyOnClose="true">
            
           <a-form :form="form">
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="单位类型">
                   <a-select placeholder="请选择" v-decorator="['companyTypeId',{rules:[{required:true,message:'请选择单位类型'}]}]">
                      <a-select-option v-for="item in companyTypeMap" :key="item.id" :value="item.id">{{item.companyTypeName}}</a-select-option>
                   </a-select>
               </a-form-item>
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="工种名称">
                   <a-input placeholder="请输入" v-decorator="['name',{rules:[{required:true,message:'工种名称不能为空'}]}]" class="form-input"></a-input>
               </a-form-item>
               
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="是否特种工">
                   <a-select placeholder="请选择" v-decorator="['isSpecial',{rules:[{required:true,message:'请选择是否特种工'}]}]">
                       <a-select-option :value="1">普通工种</a-select-option>
                       <a-select-option :value="2">特殊工种</a-select-option>
                   </a-select>
               </a-form-item>
               
               
               <a-row class="form-btn-group">
                   <a-button class="btn-save" @click="submit" :loadding="submitLoadding" :disabled="submitLoadding"><a-icon v-if="!submitLoadding" type="save" />保存</a-button>
                   <a-button class="btn-cancel"  @click="workTypeModal.visible = false">取消</a-button>
               </a-row>
           </a-form>

        </a-modal>
    </section>
</template>


<script>
const columns = [
    {title:'序号',dataIndex:'key',align:'center',key:'key'},
    {title:'单位类型',dataIndex:'companyTypeName',align:'center',key:'companyTypeName'},
    {title:'工种名称',dataIndex:'name',align:'center',key:'name'},
    {title:'是否特种工',dataIndex:'isSpecial',scopedSlots:{customRender: "isSpecial"},align:'center',key:'isSpecial'},
    {title:'状态',dataIndex:'status',scopedSlots:{customRender: "status"},align:'center',key:'status'},
    {title:'操作',scopedSlots:{customRender: "action"},align:'center'},
]
export default {
    data(){
        return{
            type:1, // type 1=>工种 2=>职务
            columns,
            total:0,
            pageSize:9,
            pageNum:1,
            workTypeLists:[],
            searchInfo:{}, //搜索
            workTypeModal:{
                visible:false,
                title:'添加工种'
            },
            workTypeId:'', //工种id
            companyTypeMap:[]
        }
    },
    beforeCreate(){
        this.form = this.$form.createForm(this)
    },
    created(){
        this.queryJobsList()
        this.queryTypeDropDownBox()
    },
    computed:{
        submitLoadding(){
            return this.$store.state.submitLoadding
        }
    },
    methods:{
        //单位类型下拉
        queryTypeDropDownBox(){
            this.$api.queryTypeDropDownBox().then(res =>{
                if(res.code === 200){
                    this.companyTypeMap = res.data || []
                }
            })
        },
        showWorkTypeModal(list){
            this.workTypeModal.visible = true
            this.workTypeModal.title = list ? '编辑工种' : '新增工种'
            this.workTypeId = list ? list.id : ''
            this.workTypeId && this.backfillForm(list)
        },
        //编辑时，回填数据到表单
        backfillForm(list){
            let formData = {
                companyTypeId:list.companyTypeId,
                name:list.name,
                isSpecial:list.isSpecial
            }
            this.$nextTick(() =>{
                this.form.setFieldsValue(formData)
            })
        },
        //查询工种列表
        queryJobsList(){
            let params = {
                type:this.type,
                current:this.pageNum,
                size:this.pageSize,
                companyTypeId:this.searchInfo.companyTypeId,
                name:this.searchInfo.name,
                status:this.searchInfo.status
            }
            this.$api.queryJobsList(params).then(res =>{
                if(res.code === 200){
                    this.workTypeLists = (res.data && res.data.records) || []
                    this.total = (res.data && res.data.total) || 0
                    this.workTypeLists.forEach((item,ind) =>{
                        item.key = (this.pageSize*(this.pageNum - 1))+ind+1
                    })
                }
            })
        },
        changePageNum(p){
            this.pageNum = p.current
            this.queryJobsList()
        },
        reset(){
            this.searchInfo = {}
            this.pageNum = 1
            this.queryJobsList()
        },
        search(){
            this.pageNum = 1
            this.queryJobsList()
        },
        //提交表单数据（添加、编辑）
        submit(){
             this.form.validateFields((err,formData) =>{
                if(!err){
                    this.$store.commit('SET_SUBMITLOADDING',true)
                    if(this.workTypeId){
                        formData.id = this.workTypeId
                    }
                    formData.type = this.type
                    let apiName = this.workTypeId ? 'updateJobs' : 'insertJobs'
                    this.$api[apiName](formData).then(res =>{
                        if(res.code === 200){
                            this.workTypeModal.visible = false
                            this.queryJobsList()
                        }
                    })
                }
            })
        },
        //删除
        deleteItem(list){
            this.$api.deleteJobs({id:list.id}).then(res =>{
                if(res.code === 200){
                    this.queryJobsList()
                }
            })
        },
        //启用急用切换
        changeStatus(list){
            let status = list.status == 1 ? 2 : 1
            this.$api.changeJobsStatus({id:list.id,status}).then(res =>{
                if(res.code === 200){
                    this.queryJobsList()
                }
            })
        }
    }
}
</script>