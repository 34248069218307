<template>
    <section>
        <section class="search-container">
            <a-form layout="inline" class="m-t-14">
                <a-form-item label="角色名称">
                    <a-input class="search-input" placeholder="请输入" v-model="searchInfo.name" />
                </a-form-item>
                <a-form-item label="状态" >
                    <a-select class="search-select" placeholder="全部" v-model="searchInfo.status">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option value="1">启用</a-select-option>
                        <a-select-option value="2">禁用</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" class="m-r-8" @click="search"><a-icon type="search" />查询</a-button>
                    <a-button class="m-r-8" @click="reset"><a-icon type="reload" />重置</a-button>
                    <a-button type="primary" @click="showRoleModal(null)">添加角色</a-button>
                </a-form-item>
            </a-form>
        </section>

        <section class="list-table-container m-t-18">
            <a-table size="small" :columns="columns" :data-source="roleLists" :pagination="{total,pageSize,hideOnSinglePage:true,showTotal:(total) => `共${total}条`,size:'default',showQuickJumper:true,showSizeChanger:false}" @change="changePageNum">
                <span slot="status" slot-scope="text" :style="{color:text == 1 ? '#52C41A' :'#FF555E'}">{{text == 1 ? '启用' : '禁用'}}</span>
                <a slot="action" slot-scope="text,list">
                   
                    <a class="m-r-10" @click="showRoleModal(list)">编辑</a>
                    
                    <a-popconfirm  class="m-r-10" :title="'确定'+(list.status == 1 ? '禁用' : '启用')+'该角色?'" @confirm="changeRoleStatus(list)">{{list.status == 1 ? '禁用' : '启用'}}</a-popconfirm>
            
                    <a-popconfirm title="确定删除该角色?" @confirm="deleteItem(list.id)">删除</a-popconfirm>
                </a>
            </a-table>
        </section>

         <a-modal centered width="660px" class="modal-container" :title="roleModal.title" v-model="roleModal.visible" :footer="false" :destroyOnClose="true">
            <a-form :form="form">
                <a-row>
                    <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="角色名称">
                        <a-input style="width:300px" placeholder="请输入" v-decorator="['name',{rules:[{required:true,message:'请输入角色名称'}]}]"></a-input>
                    </a-form-item>
                </a-row>
                <a-row>
                    <a-form-item label="角色说明" :label-col="{span:4}" :wrapper-col="{span:18}">
                        <a-textarea rows="4" v-decorator="['remarks',{rules:[{required:true,message:'请输入角色说明'}]}]" placeholder="请输入" style="width:600px" ></a-textarea>
                    </a-form-item>
                </a-row>
                <a-row class="form-btn-group" style="padding-left:80px">
                   <a-button class="btn-save" @click="submit" :loadding="submitLoadding" :disabled="submitLoadding"><a-icon v-if="!submitLoadding" type="save" />保存</a-button>
                   <a-button class="btn-cancel"  @click="roleModal.visible = false">取消</a-button>
               </a-row>
            </a-form>
        </a-modal>

    </section>
</template>


<script>
const columns = [
    {title:'序号',dataIndex:'key',align:'center',key:'key'},
    {title:'角色名称',dataIndex:'name',align:'center',key:'name'},
    {title:'角色说明',dataIndex:'remarks',align:'center',key:'remarks'},
    {title:'更新时间',dataIndex:'updateTime',align:'center',key:'updateTime'},
    {title:'状态',dataIndex:'status',align:'center',key:'status',scopedSlots:{customRender: "status"}},
    {title:'操作',scopedSlots:{customRender: "action"},align:'center'},
]
export default {
    data(){
        return{
            columns,
            total:0,
            pageNum:1,
            pageSize:9,
            roleLists:[],
            searchInfo:{},
            roleModal:{
                visible:false
            },
            roleId:''
        }
    },
    beforeCreate(){
        this.form = this.$form.createForm(this)
    },
    created(){
        this.queryRoleList()
    },
    computed:{
        submitLoadding(){
            return this.$store.state.submitLoadding
        }
    },
    methods:{
        //查询角色列表
        queryRoleList(){
            let params = {...this.searchInfo}
            params.current = this.pageNum
            params.size = this.pageSize
            this.$api.queryRoleList(params).then(res =>{
                if(res.code === 200){
                    this.roleLists = (res.data && res.data.records) || []
                    this.total = (res.data && res.data.total) || 0
                     this.roleLists.forEach((item,ind) =>{
                         item.key = (this.pageSize*(this.pageNum - 1))+ind+1
                     })
                }
            })
        },
        reset(){
            this.searchInfo = {}
            this.pageNum = 1
            this.queryRoleList()
        },
        search(){
            this.pageNum = 1
            this.queryRoleList()
        },
        changePageNum(p){
            this.pageNum = p.current
            this.queryRoleList()
        },
        //删除
        deleteItem(id){
            this.$api.deleteRole({id}).then(res =>{
                if(res.code === 200){
                    this.queryRoleList()
                }
            })
        },
        //启用禁用角色
        changeRoleStatus(list){
            let id = list.id
            let status = list.status == 1 ? 2 : 1
            this.$api.changeRoleStatus({id,status}).then(res =>{
                if(res.code === 200){
                    this.queryRoleList()
                }
            })
        },
        showRoleModal(list){
            this.roleModal.visible = true
            this.roleId = list ? list.id : ''
            this.roleModal.title = this.roleId ? '编辑角色' : '新增角色'
            this.roleId && this.queryRoleDetails()
        },
        queryRoleDetails(){
            this.$api.queryRoleDetails({id:this.roleId}).then(res =>{
                if(res.code === 200){
                   
                    let role = (res.data && res.data.role) || []
                    this.form.setFieldsValue({
                        name:role.name,
                        remarks:role.remarks
                    })
                }
            })
        },
        //新增编辑角色
        submit(){
            this.form.validateFields((err,formData) =>{
                if(!err){
                    this.$store.commit('SET_SUBMITLOADDING',true)
                    if(this.roleId){
                        formData.id = this.roleId
                    }
                    let params = {
                      role:  formData
                    }
                    let apiName = this.roleId ? 'updateRole' : 'insertRole'
                    this.$api[apiName](params).then(res =>{
                        if(res.code === 200){
                            this.queryRoleList()
                            this.roleModal.visible = false
                        }
                    })
                }
            })
        }
    }
}
</script>